<template>
  <div>
    <v-app-bar height="100vh" app color="transparent" flat hide-on-scroll>
      <v-container class="pa-7">
        <v-row>
      <v-img
        :max-width="$vuetify.breakpoint.mdAndDown ? '22vw' : '12vw'"
        contain
        :src="logo"
      ></v-img>

      <v-spacer></v-spacer>
      <v-btn
        dark
        color="primary"
        :large="$vuetify.breakpoint.smAndUp"
        elevation="3"
        class="float-right"
        style="text-decoration:none"
        @click="navigateToWaitlist"
        >JOIN WAITLIST
      </v-btn>
      </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>
  
<script>
import logo from '@/assets/graphicito_logo.png';

export default {
  name: 'Header',
  data: () => ({
    logo,
    drawer: false,
    
  }),
  methods:{
    navigateToWaitlist(){
      this.$emit('navigateToWaitlist');
    }
  }
};
</script>

<style>
</style>