import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import User from './models/User'
import Auth from './models/Auth'
import VuexORMGraphQL from '@vuex-orm/plugin-graphql'
import CustomAdapter from './custom-adapter'
import state from './state'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

// Create a new instance of Database.
const database = new VuexORM.Database()

// Register Models to Database.
database.register(User);
database.register(Auth);

const options = {
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network'
        }
    },
    connectionQueryMode: 'nodes',
    database: database,
    url: 'http://localhost:3000/graphql',
    includeExtensions: true,
    debug: process.env.NODE_ENV !== 'production',
    adapter: new CustomAdapter()
}

VuexORM.use(VuexORMGraphQL, options)

// Create Vuex Store and register database through Vuex ORM.
const store = new Vuex.Store({
    state,
    mutations,
    actions,
    plugins: [VuexORM.install(database)]
})

export default store