<template>
  <div style="min-height: 100vh" class="primary mx-n8">
    <div v-if="submitted" class="wrapper">
      <div v-for="i in 149" :key="i" :class="`confetti-${i}`"></div>
    </div>
    <v-row
      no-gutters
      style="width: 100%; height: 100%"
    >
      <v-col cols="12" lg="6" class="white" style="z-index: 3">
        <div class="pa-15">
          <v-img
            src="../../assets/graphicito_logo.png"
            width="25%"
            class="mb-10"
          ></v-img>
          <h1
            style="
              font-weight: bold;
              font-size: 3rem;
              color: #1e1f24;
              width: 80%;
            "
          >
            Create Designs Without Designing
          </h1>
          <p class="mt-8">
            Create unlimited image content, without complicated software
          </p>
          <v-form
            v-if="!submitted"
            v-model="valid"
            id="waitlist-form"
            @submit.prevent="submitForm"
            class="mt-15"
          >
            <v-text-field
              label="Name"
              v-model="formData.Name"
              required
              outlined
            ></v-text-field>
            <v-text-field
              label="E-mail"
              v-model="formData.Email"
              outlined
              required
              :rules="emailRules"
              type="email"
            ></v-text-field>
            <v-text-field
              v-model="formData['Phone Number']"
              outlined
              placeholder="+60xxxxxxxxx"
            >
              <template v-slot:label>
                Phone Number <small>(Optional)</small>
              </template>
            </v-text-field>
            <v-text-field
              v-model="formData.Industry"
              outlined
              placeholder="IT Services, Food & Beverages"
            >
              <template v-slot:label>
                Industry <small>(Optional)</small>
              </template>
            </v-text-field>
            <v-btn
              :disabled="!valid || loading"
              form="waitlist-form"
              type="submit"
              x-large
              class="mt-3 mb-15"
              color="primary"
              >Join Waitlist</v-btn
            >
          </v-form>
          <h5 class="mt-15" v-else style="line-height: 2;">
            Excited to have you on-board, {{formData.Name || ""}}! You are now one step closer to design all your marketing collaterals automatically. We will keep you notified for updates!
          </h5>
        </div>
      </v-col>
      <v-col cols="12" lg="6" v-show="$vuetify.breakpoint.lg">
        <div
          :class="{ 'position-absolute': $vuetify.breakpoint.lgAndUp }"
          class="mt-15"
          style="left: -20%; z-index: 1"
        >
          <Lottie
            :class="$vuetify.breakpoint.mdAndUp ? 'd-flex' : 'd-none d-sm-flex'"
            :width="1000"
            path="lottie/waitlist-anim.json"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get, vueSubmitForm } from "custom-gform";
import Lottie from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "waitlist",
  components: {
    Lottie,
  },
  // async mounted() {
  //   this.gForm = await get(
  //     "https://docs.google.com/forms/d/e/1FAIpQLSdqiaNbWEY3z9mm9TvhRtF9UKwv9fxIAcpVySh7VoyMSNaJsQ/viewform"
  //   );
  // },
  data: () => ({
    gForm: {"formAction":"https://docs.google.com/forms/u/0/d/e/1FAIpQLSdqiaNbWEY3z9mm9TvhRtF9UKwv9fxIAcpVySh7VoyMSNaJsQ/formResponse","formTitle":"Graphicito ICG Waitlist","formDescription":"","questions":[{"type":"short-answer","entry":"entry.2075627292","title":"Name","description":null,"required":true,"placeholder":""},{"type":"short-answer","entry":"entry.319608032","title":"Email","description":null,"required":true,"placeholder":""},{"type":"short-answer","entry":"entry.1763564695","title":"Phone Number","description":null,"required":false,"placeholder":""},{"type":"short-answer","entry":"entry.2125898176","title":"Industry","description":null,"required":false,"placeholder":""}]},
    formData: {
      Name: "",
      Email: "",
      "Phone Number": "",
      Industry: "",
    },
    valid: false,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    submitted: false,
    loading: false,
  }),
  methods: {
    async submitForm() {
      this.loading = true;
      this.$ga.event({
        eventCategory: 'cta',
        eventAction: 'click',
        eventLabel: 'submit form',
        eventValue: 1
      })
      await vueSubmitForm(this.gForm, this.formData).then(() => {
        this.submitted = true;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  overflow: hidden;
}

.wrapper {
  position: absolute;
  z-index: 600;
  min-height: 100vh;
  width: 100%;
}

[class|="confetti"] {
  position: absolute;
}

$colors: (#d13447, #ffbf00, #263672);

@for $i from 0 through 150 {
  $w: random(8);
  $l: random(100);
  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w * 0.4}px;
    background-color: nth($colors, random(3));
    top: -10%;
    left: unquote($l + "%");
    opacity: random() + 0.5;
    transform: rotate(#{random() * 360}deg);
    animation: drop-#{$i}
      unquote(4 + random() + "s")
      unquote(random() + "s")
      infinite;
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l + random(15) + "%");
    }
  }
}
</style>