import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
                primary: '#e8505b', 
                secondary: '#f9d56e', 
                tertiary: '#f3ecc2',
                accent: '#14b1ab', 
          },
        },
    },
});
