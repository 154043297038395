<template>
  <div class="pa-5">
    <v-card flat>
      <v-card-title>
        samples here
      </v-card-title>
    </v-card>
  </div>
  <!-- <v-card
    max-height="300%"
    min-width="100%"
    style="border: #ffffff; background-color: #ffffff"
    outlined
  >
    <v-container class="justify-center" style="width: 70%">
        <v-card style="background-color: #ffffff00; width: 90%" elevation="0">
      <v-row no gutters v-if="$vuetify.breakpoint.mobile">
          <v-col cols="12" lg="4">
            <v-img
              width="100%"
              height="100%"
              src="../../assets/intro.png"
            ></v-img>
        </v-col>
        <v-col lg="8" cols="12">
            <v-card-title class="font primary--text" style="font-size: 20px"
              ><b>Multiple Images, One Click</b></v-card-title
            >
            <v-card-title
              class="font mt-n5"
              style="color: black; font-size: 15px"
              >Lorem ipsum dolor sit amet, s, eget blandit nisi rhoncus. Mauris
              auctor laoreet purus, id feugiat nulla cursus eget. Donec dolor
              justo, mattis eget sagittis non, vulputate pretium lacus. Nullam
              gravida dolor ut tellus tempor, nec consectetur nisi dapibus.
              Nulla commodo pellentesque quam at sagittis. Donec porta eu
            </v-card-title>
          
        </v-col>
      </v-row>
      <v-row no gutters v-else>
        <v-col lg="8" cols="12">
            <v-card-title class="font primary--text" style="font-size: 20px"
              ><b>Multiple Images, One Click</b></v-card-title
            >
            <v-card-title
              class="font mt-n5"
              style="color: black; font-size: 15px"
              >Lorem ipsum dolor sit amet, s, eget blandit nisi rhoncus. Mauris
              auctor laoreet purus, id feugiat nulla cursus eget. Donec dolor
              justo, mattis eget sagittis non, vulputate pretium lacus. Nullam
              gravida dolor ut tellus tempor, nec consectetur nisi dapibus.
              Nulla commodo pellentesque quam at sagittis. Donec porta eu
            </v-card-title>
          
        </v-col>
         <v-col cols="12" lg="4">
            <v-img
              width="100%"
              height="100%"
              src="../../assets/intro.png"
            ></v-img>
        </v-col>
      </v-row>
      </v-card>
    </v-container>
  </v-card> -->
</template>

<script>

export default {
  name: "Introduction",
  data() {
    return {
      url: "",
      urlRules: [(v) => !!v || "Url is required to generate image"],
      image: "",
    };
  },
};
</script>

<style>
.hoverCard {
  transition: opacity 0.4s ease-in-out;
}

.hoverCard:not(.on-hover) {
  opacity: 0.6;
}

.show-btns {
  color: rgb(255, 255, 255) !important;
}
</style>


