<template >
  <div class="my-13">
    <!-- <h2 class="font3 primary--text mt-5" align="center">
      Pricing
    </h2> -->
    <!-- <h3 class="font4 primary--text" align="center">Join us now!</h3> -->
      <h1 class="text-center display-1">Subscription Plans</h1>
      <h5 class="text-center">Our plans start from only RM 0!</h5>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" lg="12" class="pa-5">
            <v-card @click="()=>{}">
              <div class="bgOverlay">
                <v-img :src="bg2" height="8vh" width="100%">
                  <v-chip class="float-right mt-3 mr-2"> UNLIMITED FREE</v-chip>
                </v-img>
              </div>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Create all you want</v-list-item-title>
                  <v-list-item-subtitle
                    >Unlimited visuals at no cost for any
                    usages</v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <v-card-title> RM 0/ Month </v-card-title>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>
<!-- 
          <v-col cols="12" lg="6" class="pa-5">
            <v-card @click="()=>{}">
              <v-img :src="bg2" height="8vh" width="100%">
                <v-chip
                  class="mt-3 mr-2 float-right"
                  color="orange"
                  text-color="white"
                >
                  PREMIUM
                  <v-icon right> mdi-crown </v-icon>
                </v-chip>
              </v-img>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Design automation with ease</v-list-item-title>
                  <v-list-item-subtitle
                    >Automate your brand building</v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <v-card-title> RM 0/ Month </v-card-title>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col> -->
          <!-- <v-col
            v-for="(data, index) in priceList"
            :key="index"
            cols="12"
            lg="4"
            md="4"
            sm="12"
          >
            <v-card class="mb-10 mx-3" rounded="lg" :elevation="index===1 ? '5' : '2'">
              <v-card-title
                class="justify-center pr-0 pl-0 pb-0 font"
                style="color: #14b1ab"
                >{{ data.title }}</v-card-title
              >
              <v-card-title
                class="justify-center mt-n6 ml-7 font"
                style="color: #14b1ab"
                >{{ data.priceSign
                }}<v-card-title
                  class="ml-n3 font"
                  style="font-size: 300%; color: #14b1ab"
                  >{{ data.priceValue
                  }}<v-card-title class="ml-n3 font" style="color: #14b1ab">{{
                    data.priceDuration
                  }}</v-card-title></v-card-title
                ></v-card-title
              >
              <v-divider class="mt-n5"></v-divider>

              <div>
                <h6
                  v-for="(i, index) in data.items"
                  class="ml-10 mr-10 pa-0 font2"
                  align="left"
                  :key="index"
                >
                  <v-icon :color="i.checked ? '#39FF14' : 'grey'"
                    >mdi-check-bold</v-icon
                  >
                  {{ i.title }}
                </h6>
                <v-divider></v-divider>
              </div>

              <v-list-item-title align="center"
                ><v-btn
                  class="mb-5 white--text"
                  style="width: 80%"
                  color="accent"
                  rounded
                  min-width="0"
                  @click="index === 1 ? one() : two()"
                  >{{ data.buttonDescription }}</v-btn
                >
              </v-list-item-title>
            </v-card>
          </v-col> -->
        </v-row>
      </v-container>
  </div>
</template>

<script>
import bg1 from "@/assets/Home/pricing_bg.png";
import bg2 from "@/assets/Home/pricing_bg1.png";

export default {
  name: "pricinglist",
  data() {
    return {
      bg1,
      bg2,
      priceList: [
        {
          packageChip: "Free",
          title: "Freemium",
          priceSign: "$",
          priceValue: "0",
          priceDuration: "/month",
          buttonDescription: "join us For Free",
          description:
            "Join us Now and start creating your custom image! For ZERO charges!",
          items: [
            {
              checked: true,
              title: "Unlimited Image Generation",
            },
            {
              checked: true,
              title: "High Resolution Downloads",
            },
            {
              checked: false,
              title: "Watermark Remover",
            },
            {
              checked: false,
              title: "Social Media Posting Scheduler",
            },
            {
              checked: false,
              title: "Custom Templates",
            },
            {
              checked: false,
              title: "Image Editor",
            },
            {
              checked: false,
              title: "Unlimited Image Generation",
            },
            {
              checked: false,
              title: "Premium Illustrations",
            },
          ],
        },
        {
          packageChip: "Most Popular",
          title: "Premium (Monthly)",
          priceSign: "$",
          priceValue: "5",
          priceDuration: "/month",
          buttonDescription: "Subscribe Now",
          description:
            "Join us Now and start creating your custom image! For ZERO charges!",
          items: [
            {
              checked: true,
              title: "Unlimited Image Generation",
            },
            {
              checked: true,
              title: "High Resolution Downloads",
            },
            {
              checked: true,
              title: "Watermark Remover",
            },
            {
              checked: true,
              title: "Social Media Posting Scheduler",
            },
            {
              checked: true,
              title: "Custom Templates",
            },
            {
              checked: true,
              title: "Image Editor",
            },
            {
              checked: true,
              title: "Unlimited Image Generation",
            },
            {
              checked: true,
              title: "Premium Illustrations",
            },
            {
              title: 'consistent styles'
            },
            {
              title: 'custom svg template'
            }
          ],
        },
        {
          packageChip: "Value for Money",
          title: "Premium (Yearly)",
          priceSign: "$",
          priceValue: "50",
          priceDuration: "/year",
          buttonDescription: "Subscribe Now",
          description:
            "Join us Now and start creating your custom image! For ZERO charges!",
          items: [
            {
              checked: true,
              title: "Unlimited Image Generation",
            },
            {
              checked: true,
              title: "High Resolution Downloads",
            },
            {
              checked: true,
              title: "Watermark Remover",
            },
            {
              checked: true,
              title: "Social Media Posting Scheduler",
            },
            {
              checked: true,
              title: "Custom Templates",
            },
            {
              checked: true,
              title: "Image Editor",
            },
            {
              checked: true,
              title: "Unlimited Image Generation",
            },
            {
              checked: true,
              title: "Premium Illustrations",
            },
          ],
        },
      ],
    };
  },
  methods: {
    one() {
      this.$alert("To Register");
    },
    two() {
      this.$alert("To Register and Subscribe");
    },
  },
};
</script>

<style>
</style>

