//Landing
import Home from '../views/LandingPage/Home2'
import WaitList from '../views/LandingPage/Waitlist.vue'


const routes = [
    { path: '/', component: Home },
    { path: '/waitlist', name:'waitlist', component: WaitList },

]

export default routes;