<template>
  <v-app>
    <transition name="fade">
      <router-view/>
    </transition>
  </v-app>
</template>

<script>
export default {
  name: "App",
  mounted(){
    this.$ga.page(this.$router)
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@400;500;600;700&display=swap');
html *{
  font-family: 'Spartan', sans-serif !important;
}
h1{
  font-weight: 600 !important;
  color: #34373b !important;
}
a {
    text-decoration: none;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>