<template>
  <v-card
    max-height="300%"
    min-width="100%"
    flat
  > 
    <v-card-title class="justify-center display-1 primary--text">What do you get?</v-card-title>
    <v-card-subtitle class="text-center">Here are the features we've prepared for you.</v-card-subtitle>
    <v-container class="justify-center" style="width:70%">
      <v-row no gutters>
        <v-col
          v-for="(data, index) in features"
          :key="index"
          cols="12"
          lg="6"
          md="6"
          sm="12"
        >
          <v-card
            rounded="lg"
            class="pa-5 tertiary"
          >
            <v-img
              width="20%"
              height="20%"
              class="float-left mt-6"
              :src="data.imageLink"
            ></v-img>
            <v-card-title
              class="primary--text"
              ><b>{{ data.title }}</b></v-card-title
            >
            <v-card-title class="font mt-n5" style="color: black; font-size: 15px">{{
              data.description
            }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "Features",
  data() {
    return {
      features: [
        {
          imageLink: require("../../assets/rocket.png"),
          title: "Unlimited Designs",
          description:
            "Say hello to unique designs on each generate. Not satisfied? Hit GENERATE again with no extra charges!",
        },
        {
          imageLink: require("../../assets/rocket.png"),
          title: "Instant Visuals",
          description:
            "Get stunning images quickly without a long wait time and produce your content faster.",
        },
        {
          imageLink: require("../../assets/rocket.png"),
          title: "Social Media and Blog Automation",
          description:
            "Manage, automate, schedule, and publish all your content in Graphicito.",
        },
        {
          imageLink: require("../../assets/rocket.png"),
          title: "Powered by AI",
          description:
            "Our AI powered engine will analyze, understand, and produce visuals that are highly relevant with your content.",
        },
      ],
    };
  },
};
</script>

<style>

</style>