<template>
  <div>
    <Header @navigateToWaitlist="navigateToWaitlist"/>
    <v-main>
      <!-- hero -->
      <div style="height: 20vh" v-if="$vuetify.breakpoint.xsOnly"></div>
      <v-container
        class="d-flex align-center"
        style="position: relative; height: 100vh"
      >
        <v-row no-gutters align="center">
          <v-col cols="12" sm="6" style="z-index: 5; width: 100%" class="mb-10">
            <h1>Design</h1>
            <BannerTitle />
            <h1>Automatically.</h1>
            <p style="width: 80%">
              Forget templates. Forget manual designs. Start creating stunning
              graphic content automatically for FREE, forever.
            </p>
            <v-btn
              style="text-decoration: none"
              @click="navigateToWaitlist"
              x-large
              class="mt-5 primary"
              >Join Waitlist</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6">
            <v-img
              style="z-index: 3"
              max-width="100vw"
              contain
              src="@/assets/hero-man.png"
            ></v-img>
          </v-col>
          <svg
            v-if="$vuetify.breakpoint.smAndUp"
            style="position: absolute; bottom: -50px; right: -50vw; z-index: 1"
            viewBox="0 0 200 200"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#e8505b"
              d="M44.6,-31.3C59.9,-16.3,76.1,2.4,74.9,21C73.8,39.5,55.3,58,32.9,69C10.5,80.1,-15.8,83.7,-35,74C-54.3,64.2,-66.4,41.1,-63.8,23.1C-61.1,5,-43.6,-7.9,-30.5,-22.4C-17.3,-36.9,-8.7,-53,3,-55.4C14.6,-57.7,29.2,-46.3,44.6,-31.3Z"
              transform="translate(100 100)"
            />
          </svg>
        </v-row>
      </v-container>
      <Playground />

      <div class="inter-shape-e d-none d-md-block"></div>
      <!-- Feature 1 -->
      <v-container style="margin-top: 5%">
        <v-row style="height: 100%">
          <v-col
            style="hegiht: 80vh"
            class="primary feature-box-1"
            cols="12"
            sm="8"
          >
            <kinesis-container>
              <kinesis-element type="depth" :strength="10">
                <v-img :src="require('@/assets/Home/unlimited.png')"> </v-img>
              </kinesis-element>
            </kinesis-container>
          </v-col>
          <v-col cols="12" sm="4">
            <div :class="$vuetify.breakpoint.smAndDown ? 'pa-4' : null">
              <h1 data-aos="underline">Designs, <span>Without Limit</span></h1>
              <p>
                Insert content once. Generate unlimited design variants.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="inter-shape-a d-none d-md-block"></div>
      <!-- Feature 2 -->
      <v-container style="height: 80vh">
        <v-row style="height: 100%">
          <v-col
            order="2"
            order-sm="1"
            cols="12"
            sm="4"
          >
            <div :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : null">
              <h1 data-aos="underline">
                One Design, <span>Automatically Resized</span>
              </h1>
              <p>
                Use the same design on Instagram, Facebook, or Twitter that are perfectly sized for each platform.
              </p>
            </div>
          </v-col>
          <v-col
            order="1"
            order-sm="2"
            class="primary feature-box-1"
            cols="12"
            sm="8"
          >
            <kinesis-container>
              <kinesis-element type="depth" :strength="10">
                <v-img
                  style="min-width: 80vw"
                  src="../../assets/size.png"
                ></v-img>
              </kinesis-element>
            </kinesis-container>
          </v-col>
        </v-row>
      </v-container>

      <div class="inter-shape-c d-none d-md-block"></div>

      <!-- Feature 3 -->
      <v-container style="margin-top: 5%">
        <v-row style="height: 100%">
          <v-col class="primary feature-box-1" cols="12" sm="8">
            <kinesis-container>
              <kinesis-element type="depth" :strength="10">
                <v-img
                  class="images mt-8"
                  src="../../assets/Home/schedule-screenshot.jpg"
                ></v-img>
              </kinesis-element>
            </kinesis-container>
          </v-col>
          <v-col cols="12" sm="4">
            <div :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : null">
              <h1 data-aos="underline">
                <span>Scheduler</span>
              </h1>
              <p>
                Post automatically to any platform on the best time and receive the
                highest engagement. Say goodbye to manual postings!
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <!-- Feature 4 -->
      <v-container style="height: 80vh; margin-top: 10%; margin-bottom: 15%">
        <v-row style="height: 100%">
          <v-col
            cols="12"
            sm="4"
            order="2"
            order-sm="1"
          >
            <div :class="$vuetify.breakpoint.smAndDown ? 'pa-4' : null">
              <h1 data-aos="underline"><span>Time and Money</span> Saver</h1>
              <p>
                Reduce monthly designing spends up to 95% by setting everything
                on automation.
              </p>
            </div>
          </v-col>
          <v-col
            style="hegiht: 80vh"
            class="primary feature-box-1"
            cols="12"
            sm="8"
            order="1"
            order-sm="2"
          >
            <kinesis-container>
              <kinesis-element type="depth" :strength="10">
                <v-img width="80%" src="../../assets/anytime-illus.svg"></v-img>
              </kinesis-element>
            </kinesis-container>
          </v-col>
        </v-row>
      </v-container>

      <!-- three steps -->
      <h1
        class="grey-darken-4--text text-center mt-12 mt-sm-12 mb-8 mx-10"
        data-aos="underline"
      >
        <span>How It Works?</span>
      </h1>
      <div
        style="position: relative; overflow-x: hidden; overflow-y: hidden"
        class="d-flex align-center justify-center mb-12 card-row"
      >
        <v-container style="z-index: 3; height: 100%">
          <v-row align="center" class="h-100">
            <v-col cols="12" sm="4" class="h-100 pa-5">
              <v-hover v-slot="{ hover }">
                <v-card
                  flat
                  dark
                  :elevation="hover ? 5 : 0"
                  :class="{ 'step-cards--float': hover }"
                  height="500px"
                  color="primary"
                  class="rounded-xl step-cards"
                >
                  <v-img
                    style="position: absolute; z-index: 5; top: 20%"
                    contain
                    :src="require('@/assets/Home/steps/fill.svg')"
                    width="80%"
                    class="ml-6"
                  ></v-img>

                  <v-card-title class="justify-center card-title">
                    Insert title and subtitles
                  </v-card-title>
                  <v-card-subtitle class="justify-center card-subtitle">
                    Fill in the title and subtitles to be included in your design.
                  </v-card-subtitle>
                  <div class="curved-bg" />
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="12" sm="4" class="h-100 pa-5">
              <v-hover v-slot="{ hover }">
                <v-card
                  flat
                  dark
                  :elevation="hover ? 5 : 0"
                  :class="{ 'step-cards--float': hover }"
                  height="500px"
                  color="primary"
                  class="rounded-xl step-cards"
                >
                  <v-img
                    style="position: absolute; z-index: 5; top: 10%"
                    contain
                    :src="require('@/assets/Home/steps/generate.svg')"
                    width="80%"
                    class="ml-6"
                  ></v-img>

                  <v-card-title class="justify-center card-title">
                    Generate designs
                  </v-card-title>
                  <v-card-subtitle class="justify-center card-subtitle">
                    Allow our AI to do the design work for you.
                  </v-card-subtitle>
                  <div class="curved-bg" />
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="12" sm="4" class="h-100 pa-5">
              <v-hover v-slot="{ hover }">
                <v-card
                  flat
                  dark
                  :elevation="hover ? 5 : 0"
                  :class="{ 'step-cards--float': hover }"
                  height="500px"
                  color="primary"
                  class="rounded-xl step-cards"
                >
                  <v-img
                    style="position: absolute; z-index: 5; top: 10%"
                    contain
                    class="ml-4"
                    width="80%"
                    :src="require('@/assets/Home/steps/share.svg')"
                  ></v-img>

                  <v-card-title class="justify-center card-title">
                    Share
                  </v-card-title>
                  <v-card-subtitle class="justify-center card-subtitle">
                    Pick your favourite design and share across any platform.
                  </v-card-subtitle>
                  <div class="curved-bg" />
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-container>
        <div
          class="tertiary position-absolute"
          style="
            height: 80%;
            width: 150%;
            z-index: 1;
            border-radius: 21% 79% 14% 86% / 55% 40% 60% 45%;
          "
        ></div>
      </div>
      <!-- <Features/> -->
      <CTA @navigateToWaitlist="navigateToWaitlist"/>
      <Footer />
    </v-main>
  </div>
</template>

<script>
import Hero from "@/components/Home/Hero";
import Playground from "@/components/Home/Playground";
import CTA from "@/components/Home/CTA";
import PricingList from "@/components/Home/PricingList";
import Features from "@/components/Home/Features";
import Introduction from "@/components/Home/Introduction";
import Header from "@/components/Home/Header";
import Footer from "@/components/Home/Footer";
import BannerTitle from "@/components/Home/BannerTitle.vue";

export default {
  name: "Home",
  methods: {
    getPhoto(path) {
      return require(`../../assets/designshow/${path}`);
    },
    navigateToWaitlist(){
      this.$ga.event({
        eventCategory: 'cta',
        eventAction: 'click',
        eventLabel: 'waitlist',
        eventValue: 1
      })
      this.$router.push({name: 'waitlist'})
    }
  },
  computed: {
    images() {
      let images = require.context("@/assets/designshow", true);
      images = images.keys().map((str) => {
        return str.substring(2, str.length);
      });

      return images;
    },
  },
  components: {
    Hero,
    Playground,
    Features,
    Introduction,
    PricingList,
    CTA,
    Header,
    Footer,
    BannerTitle,
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";

.feature-box-1 {
  border-radius: 30% 70% 70% 30% / 55% 30% 70% 45%;
}

.modified-cards {
  border-radius: 20px !important;
}

.images {
  -webkit-box-shadow: -1px 10px 33px -25px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 10px 33px -25px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 10px 33px -25px rgba(0, 0, 0, 0.75);
}

.h-100 {
  height: 100%;
}

h1 {
  span {
    background-image: linear-gradient(transparent calc(65% - 5px), #f9d56e 5px);
    background-size: 0;
    background-repeat: no-repeat;
    display: inline;
    transition: 0.5s ease;
  }

  &:hover {
    span {
      background-size: 100%;
    }
  }
}

[data-aos="underline"] {
  animation-delay: 0.1s;
  background-size: 0;

  &.aos-animate {
    span {
      background-size: 100%;
      animation-timing-function: ease;
      animation-duration: 2s;
    }
  }
}

.curved-bg {
  border-radius: 20px !important;
  clip-path: circle(77.2% at 25% 0);
  background: white;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}

.card-title {
  z-index: 5;
  position: absolute;
  bottom: 15%;
  left: 10px;
}
.card-subtitle {
  z-index: 5;
  position: absolute;
  bottom: 8%;
  left: 10px;
}

.card-row {
  height: 80vh;
}

@media only screen and (max-width: 600px) {
  .card-row {
    height: auto;
  }
}

.inter-shape {
  height: 20vh;
  width: 20vh;
  margin-left: 15vw;
  margin-top: 5vw;
  margin-bottom: 1vw;
  border-radius: 10vh;
  background-color: #fd8368;
}

.inter-shape-e {
  height: 4vh;
  width: 34vh;
  margin-left: auto;
  margin-top: 5vw;
  margin-bottom: 1vw;
  border-radius: 10vh;
  background-color: #fd8368;
  transform: rotate(45deg);
}

.inter-shape-f {
  height: 4vh;
  width: 16vh;
  margin-bottom: 1vw;
  border-radius: 10vh;
  background-color: #fd8368;
  transform: rotate(37deg) translateY(-137px);
  z-index: -1;
}

.inter-shape-d {
  height: 20vh;
  width: 5vh;
  transform: rotate(40deg);
  margin-left: 70vw;
  margin-top: 5vw;
  margin-bottom: 5vw;
  background-color: #14b1ab;
}

.inter-shape-a {
  height: 20vh;
  width: 20vh;
  right: 50px;
  border-radius: 40px;
  transform: rotate(30deg);
  background-color: #14b1ab;
  z-index: 500;
}

.inter-shape-b {
  height: 10vh;
  z-index: -1;
  width: 60vh;
  left: 30%;
  margin-top: 70%;
  border-radius: 40px;
  transform: rotate(70deg);
  background-color: #db4c4c;
  z-index: 500;
}

.inter-shape-c {
  height: 10vh;
  width: 10vh;
  left: 10px;
  margin-left: auto;
  border-radius: 40px;
  background-color: #db4c4c;
  z-index: 500;
  position: relative;
}

.step-cards {
  transition: 0.3s;
}

.step-cards--float {
  transform: scale(1.01);
}
</style>