<template>
    <div class="u-footer">
        <b-container fluid>
            <b-row  style="background-color:black">
                <b-col sm="12" md="6" order-md="1" order-sm="3" order="3">
                    <div class="logo-container">
                        <b-img style="height:60%;width:45%;" fluid :src="require('../../assets/graphicito_whitelogo.png')"></b-img>
                        <ul class="hor-li mt-4 mb-4">
                            <li><a href="https://www.facebook.com/graphicito">Facebook</a></li>
                            <li><a href="http://instagram.com/graphicito">Instagram</a></li>
                        </ul>
                        <span class="mt-5">Graphicito © 2020</span>
                    </div>
                </b-col>

                <b-col style="justify-content:flex-end" order-md="2" md="6" sm="12" order-sm="2"  order="2">
                    <div  class="addr-cont">
                        <p>
                            m: hello@graphicito.com
                        </p>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name:'uni-footer',
    data(){
        return{
            navs:['home','about','work','contact']
        }
    },
    methods:{
        capitalize(str){
            return str[0].toUpperCase() + str.slice(1);
        },
        isRoute(curNav){
            if(curNav == 'home')
                return this.routeName == 'index'

            return curNav == this.routeName
        }
    },
    computed:{
        routeName(){
            return this.$route.name
        }
    }
    
}
</script>

<style lang="scss">
.u-footer{
    background-color: black;
    color: rgb(175, 175, 175);
    height:40vh;

    .logo-container{
        padding: 3rem;
        padding-left: 5rem;
    }

    .hor-li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        color: rgb(175, 175, 175);

        li {
            float: left;
            
        }

        li:first-child a{
            padding: 16px 0;
        }

        li a {
            display: inline;
             color: rgb(175, 175, 175);
            text-align: center;
            padding: 16px;
            text-decoration: none;
        }

        li a:hover{
            color:white;
        }
    }

    .nav-li{
        list-style-type: none;
        color: #afafaf;
        padding: 0rem 0;
        width: 50%;
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;

        a {
            display: inline;
             color: rgb(175, 175, 175);
            text-align: center;
            text-decoration: none;
        }

        a:hover{
            color:white;
            text-decoration: none;
        }
    }

    .addr-cont{
        width:90%;
        padding: 3rem 1rem;
        li{
            display: inline;
        }
    }
}

@media (max-width: 768px){
    .foot-nav{
        padding-left: 2rem;

    }
    .nav-li {
        margin-top:2rem !important;
        li {
            margin-left: 1rem;
            display: inline;
        }

        a{
            &:first-child li{
                margin-left: 0;
            }
        }

        li a {
            display: inline;
             color: rgb(175, 175, 175);
            text-align: center;
            padding: 16px;
            text-decoration: none;

        }

        li a:hover{
            color:white;
        }
    }
    .u-footer{
        .logo-container{
            padding: 1rem 2rem;
        }

        .addr-cont{
            padding: 0 2rem;
        }
    }


}
</style>