<template>
  <div>
    <v-card
      class="background justify-center"
      height="100%"
      flat
      tile
      align="center"
    >
      <div class="py-15">
        <v-card-title
          :class="`justify-center white--text font-weight-bold' + ${
            $vuetify.breakpoint.mobile ? 'display-2' : 'display-3'
          }`"
        >
          Design Automatically.
        </v-card-title>

        <div>
          <v-card-title class="white--text justify-center title">
            Instantly generate visuals for social media, blog posts, events and
            more for free.
          </v-card-title>
        </div>

        <div>
          <v-btn :href="'/getStarted'" dark color="primary" large
            >START GENERATING
          </v-btn>
        </div>
      </div>
    </v-card>
    <!-- <v-card rounded="0" class="pt-10 pb-16" flat v-if="$vuetify.breakpoint.mobile">
      <h1 class="banner2 mt-8" align="center" style="font-size: 32px">
        Design Anything
      </h1>
      <h1 class="banner2 " align="center" style="font-size: 42px">
        Automatically
      </h1>
      <h1 class="banner2" align="center" style="font-size: 16px">
        Picito allows you to generate unlimited visual content instantly and
        automatically, for FREE!
      </h1>
      <div style=' display:flex; align-items:center; justify-content:center'>
      <v-btn :href="'/getStarted'" large color="black" min-width="25%" class="white--text mt-10">GET STARTED FOR FREE</v-btn>
    </div>
    </v-card>
    <v-card rounded="0" color="#F3ECC2" class="pt-10 pb-16" flat v-else>
      <h1 class="banner2 mt-8" align="center" style="font-size: 68px">
        Design Anything
      </h1>
      <h1 class="banner2 " align="center" style="font-size: 138px">
        Automatically
      </h1>
      <h1 class="banner2" align="center" style="font-size: 22px">
        Picito allows you to generate unlimited visual content instantly and
        automatically, for FREE!
      </h1>
      <div style=' display:flex; align-items:center; justify-content:center'>
      <v-btn :href="'/getStarted'" large color="black" min-width="25%" class="white--text mt-10">GET STARTED FOR FREE</v-btn>
    </div>
    </v-card> -->
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400&display=swap");

.banner2 {
  font-family: "Raleway", sans-serif;
  background: black;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.background {
  background-image: linear-gradient(rgba(19, 7, 26, 0.123)),
    url("../../assets/Home/hero.gif");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>


      
