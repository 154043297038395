<template>
  <div :class="`${$vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-8'} secondary clipped mb-15`">
    <div flat min-height="50vh">
      <h1 class="text-center mb-0">Demo</h1>
      <v-card-subtitle class="text-center"
        >Explore how you can use the image generator with our sample
        results!</v-card-subtitle
      >
      <v-row justify="center">
        <!-- :items="['Instagram Post', 'Poster', 'Facebook Cover']" -->
        <v-select
          :items="['Facebook', 'eCommerce', 'Blog']"
          v-model="imageType"
          label="Image Type"
          class="text-center"
          :style="`max-width: ${
            $vuetify.breakpoint.smAndDown ? '95%' : '60%'
          }`"
          solo
          @change="
            showImg = false;
            currentImg = 0;
          "
        ></v-select>
      </v-row>
      <v-row v-show="imageType">
        <v-col cols="12" sm="6" order="2" order-sm="1">
          <v-card class="white rounded-xl" elevation="5" width="100%">
            <div class="w-100">
              <v-card-title class="mb-8 pt-10">
                General Information
                <small class="mb-8">Click on Show Cached Result to see the cached results!</small>
              </v-card-title>

              <v-card-text>
                <v-text-field
                  label="Title"
                  v-model="cachedInput[currentImg].title"
                  readonly
                  disabled
                  outlined
                ></v-text-field>

                <v-text-field
                  v-for="(subtitle, index) in cachedInput[currentImg].subtitles"
                  :key="index"
                  label="Subtitle"
                  :value="subtitle"
                  readonly
                  disabled
                  outlined
                ></v-text-field>

                <v-combobox
                  v-model="cachedInput[currentImg].keyword"
                  chips
                  label="Keywords"
                  multiple
                  disabled
                  readonly
                  outlined
                >
                  <template v-slot:selection="{ attrs, item, selected }">
                    <v-chip v-bind="attrs" :input-value="selected" close>
                      <strong>{{ item }}</strong
                      >&nbsp;
                    </v-chip>
                  </template>
                </v-combobox>
              </v-card-text>
              <v-card-actions class="pa-5">
                <v-btn
                  :disabled="currentImg >= 2 || !imageType"
                  @click="toggleShow"
                  large
                  color="primary"
                >
                  Show cached result
                </v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" order="1" order-sm="2">
          <v-sheet class="primary rounded-xl pa-8" elevation="5">
            <v-img
              class="rounded-xl"
              contain
              :src="showImg ? cachedInput[currentImg].image : playgroundPic"
            ></v-img>
            <h4 v-if="!showImg" class="white--text mt-8">
              Click on Show Cached Result
            </h4>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import playgroundPic from "@/assets/playground.png";

export default {
  name: "Playground",
  data() {
    return {
      playgroundPic,
      imageType: "",
      showImg: false,
      currentImg: 0,
    };
  },
  methods: {
    toggleShow() {
      if (!this.showImg) {
        this.showImg = true;
        return;
      }
      this.currentImg++;
    },
  },
  computed: {
    cachedInput() {
      if (!this.showImg) {
        return [
          {
            title: "",
            subtitle: "",
            keyword: [],
            image: require("../../assets/Home/cached/insta-post-cached.png"),
          },
        ];
      }
      switch (this.imageType) {
        // case "Instagram Post":
        //   return [
        //     {
        //       title: "Share Your Design Everywhere",
        //       subtitle: "Coming Soon",
        //       keyword: ["light", "organic"],
        //       image: require("../../assets/Home/cached/insta-post-cached.png"),
        //     },
        //     {
        //       title: "Everything You Need",
        //       subtitle: "Excel Technologies",
        //       keyword: ["techy", "geometric", "edgy"],
        //       image: require("../../assets/Home/cached/insta-post-cached-1.png"),
        //     },
        //     {
        //       title: "Hiring Pet Sitters",
        //       subtitle: "Gimmy Pet Shop",
        //       keyword: ["fun", "pet"],
        //       image: require("../../assets/Home/cached/insta-post-cached-2.png"),
        //     },
        //   ];
        // case "Poster":
        //   return [
        //     {
        //       title: "The Blank Spot",
        //       subtitle: "Coming Soon",
        //       keyword: ["Black and white", "clean"],
        //       image: require("../../assets/Home/cached/poster-cached.png"),
        //     },
        //     {
        //       title: "Charles' Fitness",
        //       subtitle: "We're Back",
        //       keyword: ["gym", "clean"],
        //       image: require("../../assets/Home/cached/poster-cached-1.png"),
        //     },
        //     {
        //       title: "Something Big Is Coming",
        //       subtitle: "goodburger.com",
        //       keyword: ["food", "yellow", "burger"],
        //       image: require("../../assets/Home/cached/poster-cached-2.png"),
        //     },
        //   ];
        // case "Facebook Cover":
        //   return [
        //     {
        //       title: "Christmas Sale",
        //       subtitle: "Coming This December",
        //       keyword: ["Christmas", "blue"],
        //       image: require("../../assets/Home/cached/facebook-cover-cached.png"),
        //     },
        //     {
        //       title: "Travel Anywhere",
        //       subtitle: "",
        //       keyword: ["night", "travel"],
        //       image: require("../../assets/Home/cached/facebook-cover-cached-1.png"),
        //     },
        //     {
        //       title: "Cake For Every Occasion",
        //       subtitle: "Visit our store now",
        //       keyword: ["bakery", "blue"],
        //       image: require("../../assets/Home/cached/facebook-cover-cached-2.png"),
        //     },
        //   ];
        case "Facebook":
          return [
            {
              title: "Console=Log",
              subtitles: ["Coming Soon"],
              keyword: ["gaming", "coming soon", "modern", "digital"],
              image: require("../../assets/Home/consoleLog1.png"),
            },
            {
              title: "Console=Log",
              subtitles: ["Coming Soon"],
              keyword: ["gaming", "coming soon", "modern", "digital"],
              image: require("../../assets/Home/consoleLog2.png"),
            },
            {
              title: "Console=Log",
              subtitles: ["Coming Soon"],
              keyword: ["gaming", "coming soon", "modern", "digital"],
              image: require("../../assets/Home/consoleLog3.png"),
            },
          ];
        case "eCommerce":
          return [
            {
              title: "Shoe Ground",
              subtitles: ["Cyber Monday", "Up to 50% discount"],
              keyword: ["ecommerce", "shoes", "sales"],
              image: require("../../assets/Home/cyberMonday1.png"),
            },
            {
              title: "Shoe Ground",
              subtitles: ["Cyber Monday", "Up to 50% discount"],
              keyword: ["ecommerce", "shoes", "sales"],
              image: require("../../assets/Home/cyberMonday2.png"),
            },
            {
              title: "Shoe Ground",
              subtitles: ["Cyber Monday", "Up to 50% discount"],
              keyword: ["ecommerce", "shoes", "sales"],
              image: require("../../assets/Home/cyberMonday3.png"),
            },
          ];
        case "Blog":
          return [
            {
              title: "Why Is Healthy Eating Important",
              subtitles: ["Free Tips"],
              keyword: ["food", "simple", "healthy"],
              image: require("../../assets/Home/healthyEating1.png"),
            },
            {
              title: "Why Is Healthy Eating Important",
              subtitles: ["Free Tips"],
              keyword: ["food", "simple", "healthy"],
              image: require("../../assets/Home/healthyEating2.png"),
            },
            {
              title: "Why Is Healthy Eating Important",
              subtitles: ["Free Tips"],
              keyword: ["food", "simple", "healthy"],
              image: require("../../assets/Home/healthyEating3.png"),
            },
          ];
      }
      return [
        {
          title: "Share Your Design Everywhere",
          subtitle: ["Coming Soon"],
          keyword: ["light", "organic"],
          image: "../../assets/Home/cached/insta-post-cached.png",
        },
      ];
    },
  },
};
</script>

<style>
.hoverCard {
  transition: opacity 0.4s ease-in-out;
}

.hoverCard:not(.on-hover) {
  opacity: 0.6;
}

.clipped {
  border-radius: 30% 70% 70% 30% / 55% 30% 70% 45%;
}
</style>