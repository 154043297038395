import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import routes from './router/router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueSimpleAlert from "vue-simple-alert";
import store from './store/index'
// import ES6 style
import {VueMasonryPlugin} from 'vue-masonry';
import AOS from "aos";
import "aos/dist/aos.css";
import { KinesisContainer, KinesisElement } from 'vue-kinesis'
import VueAnalytics from 'vue-analytics'

Vue.use(VueAnalytics, {
  id: 'UA-167744375-2'
})
Vue.component('kinesis-container', KinesisContainer)
Vue.component('kinesis-element', KinesisElement)

Vue.use(VueMasonryPlugin)

Vue.use(VueSimpleAlert);

library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueRouter)
Vue.config.productionTip = false



const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  vuetify,
  router,
  store,
  created(){
    AOS.init()
  },
  render: h => h(App)
}).$mount('#app')
